<template>
  <div class="table-item" :key="name">
    <div class="table-tile" :title="name" :id="item.item_id">
      <div class="table-tile__item">
        <div class="table-tile__item-block block-media">
          <img-product
          class="rma-product_img pr-2"
          :imgPath="image"
          :alt="name"
          :styleImage="{ maxWidth: '100%' }"
          />
        </div>
        <div class="table-tile__item-block block-name">
          <span class="title" v-html="name"></span>
        </div>
        <div class="table-tile__item-block block-reason">
          <span class="value" v-if="reason.field_id === 9">{{ reason.label }}</span>
          <span class="value" v-else-if="reason">{{ reason.value }}</span>
        </div>
        <div class="table-tile__item-block block-type">
          <span class="value" v-if="rmaType.field_id === 3">{{ rmaType.label }}</span>
          <span class="value" v-else-if="rmaType">{{ rmaType.value }}</span>
        </div>
        <div class="table-tile__item-block block-condition">
          <span class="value" v-if="condition.field_id === 12">{{ condition.label }}</span>
          <span class="value" v-else-if="condition">{{ condition.value }}</span>
        </div>
        <div class="table-tile__item-block block-qty">
          <span class="label mr-2">{{ $t('rma.summary.qty') }}:</span>
          <span class="value t-regular">{{ item.qty }}</span>
        </div>
        <div class="table-tile__item-block block-subtotal block-latest">
          <span class="value f-bold">{{ subtotal | price($store) }}</span>
        </div>
      </div>
      <div class="table-tile__additional-info">
        <div class="additional-info-wrapper">
          <div class="info-row notes" v-show="item.message && item.message.length">
            <div class="info-row-title">
              {{ $t('rma.summary.notes') }}
            </div>
            <p class="info-row-content">{{ item.message }}</p>
          </div>
          <div class="info-row attachment" v-show="item.upload_attachments && item.upload_attachments.length">
            <div class="info-row-title">
              {{ $t('rma.summary.attachments') }}
            </div>
            <div class="info-row-content">
              <ul class="files-list">
                <li v-for="(url, index) in item.upload_attachments" :key="index" class="filename-list">
                  <img :src="url.content">
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import find from 'lodash/find'
import get from 'lodash/get'
import Config from 'config'
import ImgProduct from 'theme/components/Product/Image'

export default {
  name: 'SummaryProduct',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    image () {
      return this.item.itemData.extension_attributes.image_url
    },
    name () {
      return this.item.itemData.name
    },
    sku () {
      return this.item.itemData.sku
    },
    color () {
      if (get(this.item, 'itemData.extension_attributes.selected_options', false)) {
        return find(this.item.itemData.extension_attributes.selected_options, { 'attribute_code': 'color' })
      }
      return false
    },
    size () {
      if (get(this.item, 'itemData.extension_attributes.selected_options', false)) {
        return find(this.item.itemData.extension_attributes.selected_options, { 'attribute_code': 'size' })
      }
      return false
    },
    rmaType () {
      let rmaType = get(this.item, 'custom_fields', false)
      if (rmaType) {
        return find(rmaType, { field_name: 'Resolution' })
      }
      return false
    },
    reason () {
      let reason = get(this.item, 'custom_fields', false)
      if (reason) {
        return find(reason, { field_name: 'Reason' })
      }
      return false
    },
    condition () {
      let condition = get(this.item, 'custom_fields', false)
      if (condition) {
        return find(condition, { field_name: 'Reason detail' })
      }
      return false
    },
    subtotal () {
      let subtotal = 0
      if (this.item && this.item.itemData) {
        let item = this.item.itemData
        let productPrice, price, discount, singleItemDiscount, qtyOrder
        if (item.discount_amount > 0) {
          discount = item.discount_amount
          price = item.price_incl_tax
          qtyOrder = item.qty_ordered
          // qty = this.item.qty
          singleItemDiscount = (discount / qtyOrder)
          productPrice = Math.abs(Math.round(((price - singleItemDiscount)) * 100) / 100)
          // subtotal = productPrice * (this.item.qty ? this.item.qty : this.item.itemData.qty_ordered)
        } else {
          productPrice = Config.Theme.priceIncludeTax ? item.base_price_incl_tax : item.base_price
        }
        subtotal = productPrice * (this.item.qty ? this.item.qty : this.item.itemData.qty_ordered)
      }
      if (this.rmaType.value === 6 || this.rmaType.value === 'Sostituzione') {
        return 0
      }
      return subtotal
    },
    swatchBackground () {
      if (this.color) {
        return {
          background: (this.color.color_type === '1' ? this.color.color_value : this.color.color_type === '2') ? `center / cover no-repeat url(${Config.API.swatchPath + this.color.color_value})` : ''
        }
      }
      return false
    }
  },
  components: {
    ImgProduct
  }
}
</script>
