<template>
  <section class="my-return-summary with-loader-absolute">
    <div class="account-content">
      <div class="account-content__header">
        <div class="content-title">
          <h1 class="title mb-4_1">{{ $t('My returns') }}</h1>
          <p class="subtitle">{{ $t('Check and confirm the data entered to complete the return request.') }}</p>
          <p class="subtitle">{{ $t('Required on:') }} <b>{{ returns.created_at }}</b></p>
          <p class="subtitle">{{ $t('Order:') }} <b>{{ returns.increment_id }}</b></p>
        </div>
      </div>
      <div class="account-content__body">
        <template v-if="returns">
          <div class="bcm-table rma-table rma-summary-table">
            <div class="table-header">
              <div class="single-field d-none d-md-block" v-for="field in fields" :key="field.key">
                <span>{{field.label}}</span>
              </div>
            </div>
            <div class="table-content">
              <div class="table-item" v-for="(item, index) in returnsItem" :key="index">
                <summary-product :item="item" />
              </div>
            </div>
            <div class="table-footer">
              <return-price :items="returnsItem" :estimate-shipping="returns" />
            </div>
          </div>
        </template>
      </div>
      <div class="account-content__footer">
        <div class="actions">
          <div class="action-back">
            <button class="btn btn-none bcm-link bcm-link-primary text-uppercase text-underline" @click.prevent="$router.go(-1)">
              {{ $t('rma.Back') }}
            </button>
          </div>
          <div class="other-actions">
            <button class="btn btn-secondary mr-2" @click.prevent="cancelRequest">
              {{ $t('rma.Cancel') }}
            </button>
            <button class="btn btn-primary" @click.prevent="openModal">
              {{ $t('rma.Confirm') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <modal-rma-confirm v-model="confirmRmaModal" @close="closeModal" @confirmReturns="delaySubmit" />
    <absolute-loader :showLoader="showLoader" mode="fixed"></absolute-loader>
    <!--  <help-modal /> -->
  </section>
</template>

<script>
import AbsoluteLoader from 'theme/components/AbsoluteLoader/AbsoluteLoader'
import SummaryProduct from 'theme/components/MyAccount/Rma/SummaryProduct'
import ReturnPrice from 'theme/components/MyAccount/Rma/ReturnPrice'
import ModalRmaConfirm from 'theme/components/Modal/ConfirmRma'
import { toast } from '@/modules/notifications'
import { replaceParameter } from '@/helpers/validators'
import { axiosErrorMessage } from '@/helpers/utils'
import uniqBy from 'lodash/uniqBy'

export default {
  name: 'MyReturnSummary',
  data () {
    return {
      isGuest: false,
      showLoader: false,
      returns: false,
      returnsItem: [],
      confirmRmaModal: false,
      itemsForShipping: '',
      fields: [
        {
          key: 'product',
          label: this.$t('rma.returns.productName')
        },
        {
          key: 'product-name',
          label: ''
        },
        {
          key: 'reason',
          label: this.$t('rma.returns.customField.reason')
        },
        {
          key: 'type',
          label: this.$t('rma.returns.customField.type')
        },
        {
          key: 'details',
          label: this.$t('rma.returns.customField.details')
        },
        {
          key: 'qty',
          label: this.$t('rma.returns.quantity')
        },
        {
          key: 'subtotal',
          label: this.$t('rma.Subtotal')
        }
      ]
    }
  },
  methods: {
    getFirstCustomFields (orderItems) {
      let firstCustomField = orderItems.map(item => {
        return this.prepareCustomFields(item.custom_fields)[0]
      })
      return uniqBy(firstCustomField, 'field_id')
    },
    prepareOrderItems (orderItems) {
      return orderItems.map(item => {
        return {
          'item_id': item.id,
          'qty': item.qty,
          'custom_fields': this.prepareCustomFields(item.custom_fields)
        }
      })
    },
    delaySubmit () {
      let _this = this
      setTimeout(() => {
        _this.confirmReturns()
      }, 500)
    },
    prepareCustomFields (customFields) {
      return Object.keys(customFields).map(function (field) {
        if (customFields[field].value) {
          return {
            'field_id': customFields[field].field_id,
            'value': customFields[field].value
          }
        }
      }).filter(cf => cf)
    },
    prepareOrderMessages (orderItems, rma) {
      return {
        'request_id': rma.id,
        'text': orderItems[0].message || '',
        'owner_id': 1,
        'owner_type': 2, // customer
        'attachments': this.mapAttachments(orderItems[0])
      }
    },
    mapAttachments (item) {
      return item.upload_attachments.map((attachment) => {
        return {
          name: attachment.name,
          file_name: attachment.content
        }
      })
    },
    getDate () {
      let today = new Date()
      let date = `${today.getFullYear()}-${(today.getMonth() + 1)}-${today.getDate()}`
      let time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`
      return `${date} ${time}`
    },
    closeModal () {
      this.confirmRmaModal = false
    },
    openModal () {
      this.confirmRmaModal = true
    },
    confirmReturns () {
      this.closeModal()
      this.$store.commit('ui/showLoader')
      let request = {
        order_id: this.returns.entity_id,
        payment_method: this.returns.payment.method,
        created_at: this.getDate(),
        updated_at: this.getDate(),
        store_id: this.$store.state.storeConfig.config.id,
        last_reply_by: 0,
        status_id: 7,
        customer_name: this.returns.customer_firstname || 'guest',
        customer_email: this.returns.customer_email,
        custom_fields: this.getFirstCustomFields(this.returnsItem),
        order_items: this.prepareOrderItems(this.returnsItem)
      }
      const _this = this
      return this.$store.dispatch('rma/createRma', { request })
        .then((rmaRes) => {
          _this.$store.commit('rma/setReturn', {})
          // not working
          return _this.$store.dispatch('rma/addThreadMessage', { threadMessage: _this.prepareOrderMessages(_this.returnsItem, rmaRes.data) })
            .catch((err) => {
              toast.error(axiosErrorMessage(err))
              throw err
            })
            .finally(() => {
              let componentToGo = _this.isGuest ? 'returnGuestComplete' : 'returnComplete'
              _this.$router.push({
                name: componentToGo,
                query: {
                  isGuest: _this.isGuest,
                  rmaId: rmaRes.data.id
                }
              })
            })
        })
        .catch((err) => {
          let error = err.response
          let msg = _this.$t('rma.returns.GenericError')
          if (error && error.data && error.data.message) {
            msg = replaceParameter(error.data.message, error.data.parameters)
          }
          toast.error(msg)
        })
        .finally(() => {
          _this.$store.commit('ui/hideLoader')
        })
    },
    cancelRequest () {
      // check if is guest
      // delete stored request
      // go to rma page
      this.$store.commit('rma/setReturn', {})
      this.backToFirstPage()
    },
    backToFirstPage () {
      // return to rma creation form
      // get right page logged | guest
      if ((typeof this.isGuest === 'boolean' ? this.isGuest : false) || this.isGuest === 'true') {
        this.$router.push({ name: 'createGuestReturn' })
      } else {
        this.$router.push({ name: 'myReturns' })
      }
    }
  },
  mounted () {
    if (process.client) {
      const _this = this
      this.isGuest = this.$route.query.isGuest || false
      const id = this.$route.params.id
      if (this.$route.params.id) {
        this.showLoader = true
        this.$store.dispatch('rma/summaryInit', id)
          .then((order) => {
            if (order && Object.keys(order).length) {
              _this.returns = order
              _this.returnsItem = order.order_items
            } else {
              toast.error(_this.$t('no-order-found'))
              setTimeout(() => {
                _this.backToFirstPage()
              }, 600)
            }
          })
          .finally(() => {
            _this.showLoader = false
          })
          .catch((err) => {
            toast.error(axiosErrorMessage(err))
            _this.showLoader = false
          })
      } else {
        this.backToFirstPage()
      }
    }
  },
  components: {
    SummaryProduct,
    ReturnPrice,
    ModalRmaConfirm,
    AbsoluteLoader
  }
}
</script>
