<template>
  <div class="return-price">
    <div class="return-price__content">
      <template>
        <div class="wrapp-segment">
          <span>{{ $t('rma.returns.shippingMethod') }}:</span>
          <span>
            <span v-if="shippingFees > 0">-</span>
            {{ shippingFees | price($store) }}
          </span>
        </div>
        <div class="wrapp-segment">
          <span>{{ $t('rma.returns.totalRefund') }}:</span> <span>{{ totalRefund | price($store) }}</span>
        </div>
      </template>
      <!-- <template v-if="shippingFees === 0">
        <div class="wrapp-segment">
          <span>{{ $t('rma.returns.totalRefund') }}:</span> <span>{{ totalRefund | price($store) }}</span>
        </div>
      </template> -->
      <absolute-loader :showLoader="showLoader"></absolute-loader>
    </div>
  </div>
</template>

<script>
import AbsoluteLoader from 'theme/components/AbsoluteLoader/AbsoluteLoader'

export default {
  data () {
    return {
      showLoader: false,
      shippingFees: 0,
      totalRefund: 0,
      total: 0,
      itemsForFees: ''
    }
  },
  props: {
    rma: {
      type: Object,
      required: false
    },
    estimateShipping: {
      type: Object,
      required: false
    },
    items: Array
  },
  methods: {
    getItemsForFees () {
      let obj = {}
      obj.requestItems = this.estimateShipping.order_items.map(item => {
        return {
          'item_id': item.id,
          'qty': item.qty.value,
          'custom_fields': Object.keys(item.custom_fields).map(function (field, index) {
            return {
              'field_id': item.custom_fields[field].field_id,
              'value': item.custom_fields[field].value
            }
          })
        }
      })
      this.itemsForFees = obj
    },
    calculate () {
      const _this = this
      if (this.totalRefund > 0) return
      let rma = this.rma
      this.items.forEach(item => {
        let discount, price, qty, qtyOrder
        if (!_this.rma) {
          discount = item.custom_fields['3'].value === 6 ? 0 : item.itemData.discount_amount
          price = item.custom_fields['3'].value === 6 ? 0 : item.itemData.price_incl_tax
          qtyOrder = item.itemData.qty_ordered
          qty = item.qty
        } else {
          discount = item.custom_fields['3'].value === 6 ? 0 : item.discount_amount
          price = item.custom_fields['3'].value === 6 ? 0 : item.price_incl_tax
          qtyOrder = item.qty_ordered
          qty = _this.rma.order_items.find(e => e.item_id === item.item_id).qty
        }

        if (qtyOrder === 1 && discount === 0) {
          _this.total += price
        } else if (qtyOrder > 1 && discount === 0) {
          _this.total += price * qty
        } else if (qtyOrder === 1 && discount > 0) {
          _this.total += price - discount
        } else if (qtyOrder > 1 && discount > 0) {
          _this.total += Math.abs(Math.round(((price - ((discount / qtyOrder) * qty))) * 100) / 100)
        }
      }, rma)

      this.totalRefund = Math.abs(Math.round((this.total - this.shippingFees) * 100) / 100)
      this.totalRefund = this.totalRefund.toFixed(2)
    }
  },
  mounted () {
    if (process.client) {
      this.showLoader = true
      const _this = this
      if (this.rma) {
        this.$store.dispatch('rma/getShippingFees', this.rma.id)
          .finally(() => {
            _this.showLoader = false
          })
      } else {
        this.getItemsForFees()
        this.$store.dispatch('rma/getEstimateShipping', this.itemsForFees)
          .then((res) => {
            _this.shippingFees = parseInt(res)
            _this.calculate()
          })
          .finally(() => {
            _this.showLoader = false
          })
      }
    }
  },
  components: {
    AbsoluteLoader
  }
}
</script>
